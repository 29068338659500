import {
  BelowLineItemPromotionEffect,
  Cart,
  CartWarning,
  ItemLevelPromotionEffect,
  ItemPresenceRequirement,
  OrderLevelPromotionEffect,
  PromotedItem,
  PromotedItemBundle,
  PromotedItemProduct,
  PromotedItemProductVariant,
  PromotionEffect,
  PromotionNotAppliedReasonType,
  PromotionNotAppliedWarning,
  PromotionRequirement,
  YumCart
} from '@pizza-hut-us-development/client-core';

export const isYumCart = (cart: Cart): cart is Cart & YumCart => 'belowLineItems' in cart;

export const isRedemptionWarning = (warning?: CartWarning): warning is CartWarning & PromotionNotAppliedWarning => {
  if (!warning) return false;
  return 'promotionId' in warning;
};

export const findPromoFromWarning = (cart: YumCart): string | undefined => (cart.warnings?.find((warning) => isRedemptionWarning(warning)) as PromotionNotAppliedWarning)?.promotionId;

export const findRedemptionWarningFromPromoOrCode = (cart: YumCart, promotionId?: string, code?: string): PromotionNotAppliedWarning => cart.warnings?.find((warning) => {
  if (isRedemptionWarning(warning)) return warning.code === code || warning.promotionId === promotionId;
  return false;
}) as PromotionNotAppliedWarning;

// PromotionRequirement type guard
export const isItemPresenceRequirement = (req: PromotionRequirement): req is PromotionRequirement & ItemPresenceRequirement => 'items' in req;

// PromotionEffect type guard
export const isItemLevelPromotionEffect = (effect: PromotionEffect): effect is PromotionEffect & ItemLevelPromotionEffect => 'items' in effect;
export const isBelowLineItemPromotionEffect = (effect: PromotionEffect): effect is PromotionEffect & BelowLineItemPromotionEffect => 'belowLineItemType' in effect;
export const isOrderLevelPromotionEffect = (effect: PromotionEffect): effect is PromotionEffect & OrderLevelPromotionEffect => 'discountDistributionConfig' in effect || 'includedItems' in effect || 'excludedItems' in effect;

// PromotedItem type guards
export const isPromotedItemProduct = (item: PromotedItem): item is PromotedItemProduct => 'productCode' in item && !('productVariantCode' in item);
export const isPromotedItemProductVariant = (item: PromotedItem): item is PromotedItemProductVariant => 'productVariantCode' in item;
export const isPromotedItemBundle = (item: PromotedItem): item is PromotedItemBundle => 'bundleCode' in item;

// Is a warning that we should display in the cart, until the user resolves it
// ie. Not a warning that will never work, OR one that the user will have to re-add
export const isResolvableWarning = (promotionWarning: PromotionNotAppliedWarning): boolean => {
  const warningReasonTypes = promotionWarning.reasons.map((reason) => reason.__typename);
  const nonResolvableTypes = warningReasonTypes.filter((type) => {
    // Resolvable warnings
    // TODO: Remove typecast when CC type bug is fixed
    if (type as any === PromotionNotAppliedReasonType.ItemPresenceRequirementNotMet) return false;
    if (type === PromotionNotAppliedReasonType.ItemQuantityRequirementNotMet) return false;
    if (type === PromotionNotAppliedReasonType.SubtotalRequirementNotMet) return false;
    if (type === PromotionNotAppliedReasonType.PromotionEffectTargetNotInCart) return false;
    // TEMP til Yum fix - PromotionIsInvalid can contain a valid reason we need to handle
    if (type === PromotionNotAppliedReasonType.PromotionIsInvalid) {
      const associatedReason = promotionWarning.reasons.find((reason) => reason.__typename === PromotionNotAppliedReasonType.PromotionIsInvalid);
      if (associatedReason?.message === 'NO_DISCOUNTABLE_SUBTOTAL') return false;
    }
    return true;
  });

  return nonResolvableTypes.length === 0;
};
